.logo-img{
    height: 24px;
    width: 24px;
}

@media (max-width: 480px){
    .logo-img{
        height: 14px;
        width: 14px;
    }
}