.no-bullets {
    list-style-type: none;
    /* Remove bullets */
    padding: 0;
    /* Remove padding */
    margin-top: 0px;
    margin-bottom: 16px;
    align-self: flex-start;
    font-size: 14px;
    font-family: "Poppins";
    font-weight: 400;
    line-height: 21px;
}

li {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 3px;
}

.mandatory-text {
    margin-left: 15px;
}

.container-load {
    align-items: center;
    min-height: 409px;
    width: 520px;
    border-radius: 15px;
    background-color: #FFFFFF;
    margin-top: 120px;
    padding: 10px;
    margin-bottom: 60px;
    display: flex;
}

@media (max-width: 480px) {
    .container-load {
        width: 95%;
        margin-top: 76px;
        margin-bottom: 16px;
    }
}

.pass-rules {
    width: 100%;
    display: none;
    max-width: 392px;
    align-items: start;
}