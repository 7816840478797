.valid-mark{
    fill: #525261;
    transition: fill 600ms ease-in-out;
}

.invalid-mark{
    fill: #FFF;
    stroke: #171725;
    stroke-width: 1;
    transition: fill 600ms ease-in-out;
}

.svg-rect{
    fill: #FFF;
}