.enter {
    opacity: 0;
    transform: scale(0);
}

.enterActive {
    opacity: 1;
    transform: scale(1);
    transition: opacity 200ms ease-in-out, transform 200ms ease-in-out;
}

.exit {
    opacity: 1;
    transform: translateX(0);
}

.exitActive {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 200ms ease-in-out, transform 200ms ease-in-out;
}