.error-div {
  min-height: 32px;
  width: 100%;
  background-color: #F5F5F6;
  flex-direction: row !important;
  align-items: center;
  color: #171725;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 21px;
  position: relative;
  padding: 8px;
  margin-bottom: -6px;
  box-sizing: border-box;
}

.err-message {
  display: flex;
  font-size: 12px;
  text-align: justify;
  margin-left: 8px;
}

.err-message a {
  color: #384AFF;
  text-decoration: none;
}


.alert-icon-div {
  display: flex;
  width: 24px;
  align-items: center;
  padding: 2px;
  fill: #931621;
}

.info-icon-div {
  display: flex;
  width: 24px;
  align-items: center;
  padding: 2px;
  fill: #EAB700;
}