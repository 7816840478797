.error-div {
  min-height: 32px;
  width: 100%;
  border-radius: 7px;
  background-color: #931621;
  flex-direction: row !important;
  align-items: center;
  color: #FFFFFF;
  font-family: "Proxima Nova";
  font-weight: 500;
  margin-top: 6px;
  position: relative;
  padding: 8px;
}

.err-message {
  display: flex;
  text-align: justify;
  max-width: 93%;
  margin-left: 8px;
}

.err-message a {
  color: inherit;
  text-decoration: underline;
}

.alert-icon-div {
  display: flex;
  width: 16px;
  align-items: center;
  padding: 2px;
  fill: #FFF;
}

@media (max-width: 480px) {
  .alert-icon-div {
    width: 24px;
  }
}