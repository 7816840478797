.logo-img{
    height: 24px;
    width: 24px;
}
.header-sub-cont{
    position: absolute;
    left:22px;
    display: flex;
    align-items: center;
    flex-direction: row;
}
.header-txt{
    margin-left: 8px;
    color: #FFFFFF;
    font-family: "Poppins Light";     
    font-size: 12px;
    line-height: 14px;
}
@media (max-width: 480px){
    .logo-img{
        height: 19px;
        width: 19px;
    }
}