.modal-container {
    min-height: 333px;
    width: 587px;
    position: absolute;
    margin: auto;
    background-color: #fff;
    border: 0px solid #979797;
    border-radius: 20px;
    padding: 16px;
    opacity: 1;
    visibility: visible;
    z-index: 20;
    top: 20%;
    transform: translateY(0);
    transition: opacity 200ms ease-in-out, transform 200ms ease-in-out;
}

.bg-blur {
    height: 100vh;
    width: 100%;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.3);
    left: 0;
    top: 0;
    z-index: 20;
}

.flex-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    justify-content: center;
}

.modal-prim-text {
    color: #171725;
    font-family: "Poppins";
    font-size: 36px;
    font-weight: 600;
    letter-spacing: -0.54px;
    line-height: 44px;
    text-align: center;
    margin-bottom: 0px;
    margin-top: 36px;
}

.modal-sec-text {
    color: #171725;
    font-family: "Poppins";
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    margin-top: 32px;
    line-height: 21px;
    margin-bottom: 0px;
    width: 100%;
    overflow-wrap: break-word;
}

.message-icon {
    height: 48px;
    width: 48px;
    margin-top: 32px;
    box-sizing: border-box;
}

.message-icon-legacy {
    height: 74px;
    width: 74px;
    margin-top: 44px;
    box-sizing: border-box;
    display: flex;
}

.close-btn {
    height: 20px;
    width: 20px;
    position: absolute;
    right: 0px;
    top: 0px;
    box-sizing: border-box;
    cursor: pointer;
}

.btn {
    height: 44px !important;
    width: 192px !important;
    margin-top: 32px !important;
    margin-bottom: 48px !important;
}

.btn-cancel {
    margin-top: -16px;
    font-size: 16px;
    text-align: center;
    color: #171725;
    background: none;
    border: none;
    font-weight: 600;
    letter-spacing: 0;
}

.btn-2 {
    height: 44px !important;
    width: 192px !important;
    margin-top: 0px !important;
    margin-bottom: 48px !important;
}

.modal-container-off {
    position: absolute;
    transform: translateY(100%);
    opacity: 0;
    bottom: 0;
    height: 0px;
    overflow: hidden;
    visibility: hidden;
}

.full-modal {
    top: 10% !important;
    color: #171725;
    font-family: "Intro Bold";
    min-height: 80%;
}

.leg-sec-text {
    margin-top: 44px;
}

.leg-btn {
    height: 44px !important;
    width: 100% !important;
    margin-top: 44px !important;
    margin-bottom: 48px !important;
    font-family: 'Raleway' !important;
}

.full-modal-sub {
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    margin-top: 50px;
}

.my-boost-txt {
    margin-bottom: 12px;
    margin-top: 11px;
    font-weight: normal;
    font-family: 'Raleway';
    font-size: 16px;
}


.full-modal-btn {
    height: 64px;
    width: 64px;
    cursor: pointer;
}

@media (max-width: 480px) {
    .modal-container {
        width: 80%;
        top: 20%;
    }

    .modal-prim-text {
        font-size: 28px;
    }

    .modal-sec-text {
        font-size: 16px;
    }

    .full-modal-sub {
        font-size: 20px;
    }

    .btn {
        align-self: center !important;
    }

    .btn-2 {
        align-self: center !important;
        margin-bottom: 0px !important;
    }

    .btn-cancel {
        font-size: 14px;
    }
}