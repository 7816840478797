.prim-input {
    height: 22px;
    width: 98%;
    color: #525261;
    font-family: "Proxima Nova";
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    padding: 1px 0px;
    border: none;
    outline: none;
    background-color: transparent;
}
.prim-input::placeholder{
    color: #525261;
}

.prim-label {
    height: 21px;
    color: #525261;
    font-family: "Proxima Nova";
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
}

.prim-label-hidden {
    height: 0px;
    opacity: 0;
}

.rectangle-shadow {
    height: 2px;
    width: 100%;    
    background-color: #C4C4CA;    
    position: absolute;
    bottom: 0;
}

.rectangle-shadow-error {
    height: 2px;
    width: 100%;    
    background-color: #931621;    
    position: absolute;
    bottom: 0;
}

.input-container {
    height: 60px;
    box-sizing: border-box;
    width: 100%;
    max-width: 392px;
    border-radius: 7px 7px 0 0;
    background-color: #fff;
    padding-top: 0px;
    position: relative;
    margin-top: 32px;
    display: flex;
}

.input-icon {
    display: flex;
    align-items: center;
    padding: 0 12px;
    width: 100%;
    flex-direction: row !important;
    justify-content: space-between;
}

.pass-container {
    width: 100%;
}

@media (max-width: 480px) {
    .prim-input {
        font-size: 16px;
    }  
}

.rectangle-shadow-active{
    background-color: #F25D12;
}


.prim-input:-webkit-autofill, .prim-input:-webkit-autofill:hover, .prim-input:-webkit-autofill:focus, .prim-input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #525261 !important;
    background-clip: content-box !important;
}