.back-img{
    height: 20px;
    width: 21px;    
}

.back-svg-file{
    stroke: #000000;
}

@media (max-width: 480px){
    .back-img{
        width: 31px;
        height: 30px;
    }
}