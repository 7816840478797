.code-container {
    display: flex;
    flex-direction: row;
    margin-top: 32px;
    min-width: 75%;
    max-width: 392px;
    justify-content: space-between;
}

.code-box {
    box-sizing: border-box;
    height: 52px;
    width: 52px;
    border: 2px solid #525261;
    font-size: 28px;
    font-weight: 800;
    letter-spacing: -0.2px;
    line-height: 32px;
    border-radius: 2px;
    background-color: #FFFFFF;
    text-align: center;
}

.code-box:focus {
    border: 2px solid #F25D12;
}

.error {
    border: 2px solid #931621;
}

.code-box:focus {
    outline: none;
}

@media (max-width: 480px) {
    .code-container {
        width: 100%;
        align-self: flex-start;
    }

    .code-box {
        height: 44px;
        width: 44px;
        font-size: 24px;
        font-weight: 600;
    }
}