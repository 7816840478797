.prim-input {
    height: 22px;
    width: 98%;
    color: #C4C4CA;
    font-family: AzoSans-Regular;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    padding: 2px 0px;
    border: none;
    outline: none;
    background-color: transparent;
}

.prim-input-spacing {
    letter-spacing: 0.75em;
}

.prim-input::placeholder {
    color: #C4C4CA;
    letter-spacing: 0;
}

.prim-label {
    height: 13px;
    color: #C4C4CA;
    font-family: AzoSans-Regular;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
}

.prim-label-hidden {
    height: 0px;
    opacity: 0;
}

.rectangle-shadow {
    height: 0px;
    width: 100%;
    background-color: #C4C4CA;
    position: absolute;
    bottom: 0;
}

.rectangle-shadow-error {
    height: 0px;
    width: 100%;
    background-color: #EA4335;
    position: absolute;
    bottom: 0;
}

.input-container {
    height: 60px;
    width: 100%;
    max-width: 392px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #979797;
    background-color: #292938;
    position: relative;
    margin-top: 3px;
    display: flex;
}

.input-container-error {
    border: 0.5px solid #EA4335;
}

.input-container-active {
    border: 1px solid #00A259;
}

.input-icon {
    display: flex;
    align-items: center;
    padding: 0 16px;
    width: 100%;
    flex-direction: row !important;
    justify-content: space-between;
}

.pass-container {
    width: 100%;
}

@media (max-width: 480px) {
    .prim-input {
        font-size: 14px;
    }

    .input-container {
        height: 52px;
        max-width: 392px;
    }
}

.rectangle-shadow-active {
    background-color: #33FF93;
}

.prim-input:-webkit-autofill, .prim-input:-webkit-autofill:hover, .prim-input:-webkit-autofill:focus, .prim-input:-webkit-autofill:active {    
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #C4C4CA !important;
    background-clip: content-box !important;
    caret-color: #C4C4CA;
}