.missing-footer {
    margin-top: 32px;
    color: #0165BC;
    font-family: boost-next;
    font-size: 18px;
    font-weight: 800;
    line-height: 24px;
    text-decoration: none;
    text-align: center;
}

.missing-footer-sec {
    margin-top: 0px;
    color: #171725;
    font-family: boost-next;
    font-size: 14px;
    font-weight: 800;
    line-height: 24px;
    text-decoration: none;
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    text-align: center;
    transition: opacity 200ms ease-in-out, transform 200ms ease-in-out, visibility 200ms ease-in-out;
}

.missing-footer-off {
    margin-top: 0px;
    color: #171725;
    font-family: boost-next;
    font-size: 14px;
    font-weight: 800;
    line-height: 24px;
    text-decoration: none;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-25%);
    text-align: center;
    transition: opacity 200ms ease-in-out, transform 200ms ease-in-out, visibility 200ms ease-in-out;
}

.expand-icon{
    margin-bottom: 0px;
    margin-top: 6px;
    text-align: center;
    align-items: center;
}

.qr-icon{
    text-align: center;
}