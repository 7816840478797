.header{
    flex-direction: row !important;
    justify-content: center;
    align-items: center;                    
    margin: auto;
}

.header-container{
    position: fixed;
    min-height: 60px;
    background-color: #161A29;
    width: 100%;
    align-items: center;
    display: flex;    
    flex-direction: column;
    top: 0;    
    z-index: 1;
}

.back{    
    color: #FFFFFF;    
    position: absolute;
    left: 15px;
    display: flex;
    align-items: center;
    text-decoration: none;
}

.back-text{
    color: #FFFFFF;
    font-family: "Proxima Nova";
    font-weight: bold;    
    font-size: 18px;
    margin-left: 14px;    
}

.loader {        
    width: 100%;
    height: 2px;       
    display: none;         
    background: #F47533;
    animation: line_r_animation 3s infinite;
}

.header-txt{
    margin-left: 8px;
    color: #FFFFFF;
    font-family: "Proxima Nova";     
    font-size: 16px;
    line-height: 14px;
}

@keyframes line_r_animation {
    0%   {left: calc(2% + 50%); width: 0%;}
    35%   {left: calc(2% + 0%); width: 100%;}
    70%   {left: calc(2% + 0%); width: 0%;}
    100%   {left: calc(2% + 0%); width: 0%;}
}

@media (max-width: 480px){

    .back-text{
        display: none;
    }    

    .header-container{
        min-height: 32px;
    }

    .header-txt{
        left:48px;
        font-size: 12px;
        padding-top: 2px;
    }

}