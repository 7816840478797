.enter {
    opacity: 0;
    transform: translateX(100%);
}

.enterActive {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 200ms ease-in-out, transform 200ms ease-in-out;
}

.exit {
    opacity: 1;
    transform: translateX(0);
}

.exitActive {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 400ms ease-in-out 400ms, transform 400ms ease-in-out 400ms;
}