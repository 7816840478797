.prim-input {
    height: 100%;
    width: 98%;
    color: #171725;
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 21px;
    padding: 2px 0px;
    border: none;
    outline: none;
    background-color: transparent;
}

.prim-input-spacing {
    letter-spacing: 0.75em;
}

.prim-input::placeholder {
    color: #757575;
    letter-spacing: 0;
}

.prim-label {
    height: 13px;
    color: #757575;
    font-family: "Poppins Medium";
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
    margin-top: 5px;
}

.prim-label-hidden {
    height: 0px;
    opacity: 0;
}

.rectangle-shadow {
    height: 0px;
    width: 100%;
    background-color: #757575;
    position: absolute;
    bottom: 0;
}

.rectangle-shadow-error {
    height: 0px;
    width: 100%;
    background-color: #EA4335;
    position: absolute;
    bottom: 0;
}

.input-container {
    height: 52px;
    width: 100%;    
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid #C4C4CA;
    background-color: #ffffff;
    position: relative;
    margin-top: 3px;
    display: flex;
}

.input-container-error {
    border: 0.5px solid #931621;
}

.input-container-active {
    border: 1px solid #FF5600;
}

.input-icon {
    display: flex;
    align-items: center;
    padding: 0 16px;
    width: 100%;
    flex-direction: row !important;
    justify-content: space-between;
}

.pass-container {
    width: 100%;
    height: 100%;
}

@media (max-width: 480px) {
    .input-container {
        height: 52px;        
    }
}

.rectangle-shadow-active {
    background-color: #33FF93;
}

.prim-input:-webkit-autofill, .prim-input:-webkit-autofill:hover, .prim-input:-webkit-autofill:focus, .prim-input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #212121 !important;
    background-clip: content-box !important;
    caret-color: #757575;
}