.header {
    flex-direction: row !important;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.header-container {
    position: fixed;
    min-height: 60px;
    background-color: #f1f1f1;
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    top: 0;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
    z-index: 1;
}

.back {
    color: #555;
    position: absolute;
    left: 15px;
    display: flex;
    align-items: center;
    text-decoration: none;
}

.back-text {
    color: #555;
    font-family: "Proxima Nova";
    font-weight: bold;
    font-size: 18px;
    margin-left: 14px;
}

.loader {
    width: 100%;
    height: 4px;
    display: none;
    background: #F25D12;
    animation: line_r_animation 3s infinite;
}

.language-selection {
    position: absolute;
    right: 24px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    stroke: #000;
}

.selected-language__container {
    min-height: 60px;
    box-sizing: border-box;
    width: 68px;
    left: 0px;
    top: 0px;
    border-radius: 8px;
    padding: 10px 8px 10px 8px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.language-selection:hover>.language-selection__content {
    max-height: 300px;
    overflow: visible;
}

.language-selection:hover {
    stroke: #F25D12;
    color: #F25D12;
}

.language-selection__content {
    width: 100%;
    max-height: 0px;
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: -8px;
}

.language-item {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 22px;
    width: 68px;
    height: 56px;
    background: #F2F2F9;
    flex: none;
    order: 7;
    align-self: stretch;
    flex-grow: 0;
    z-index: 0;
}

.language-item:first-child {
    border-bottom: 0.5px solid #E0E0E0;
    border-radius: 8px 8px 0px 0px;
}

.language-item:last-child {
    border-bottom: 0.5px solid #C4C4CA;
    border-radius: 0px 0px 8px 8px;
}

.language-item:hover>a {
    text-decoration: none;
    color: #F79E71;
}

.language-item:hover {
    border-bottom: none;
    background: #FFFFFF;    
}



.icon-container {
    display: flex;
}

@keyframes line_r_animation {
    0% {
        left: calc(2% + 50%);
        width: 0%;
    }

    35% {
        left: calc(2% + 0%);
        width: 100%;
    }

    70% {
        left: calc(2% + 0%);
        width: 0%;
    }

    100% {
        left: calc(2% + 0%);
        width: 0%;
    }
}

@media (max-width: 480px) {

    .back-text {
        display: none;
    }

}