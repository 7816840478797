.prof-container {
    display: flex;
    flex-direction: row;
    border-radius: 16px;
    padding: 6px;
    justify-content: space-between;
    align-items: center;
    width: 70%;
}

.m-b-16 {
    margin-bottom: 16px !important;
}

.m-top-32 {
    margin-top: 32px !important;
}