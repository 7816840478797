.no-bullets {
    list-style-type: none; /* Remove bullets */
    padding: 0; /* Remove padding */
    margin-top: 35px;
    margin-top: 0px;
    margin-bottom: 16px;
    color: #FFFFFF;    
}

li{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 3px;
}

.mandatory-text{
    margin-left: 15px;
}

.container-load{    
    align-items: center;
    min-height: 409px;
    width: 520px;
    border-radius: 15px;
    background-color: #000;
    margin-top: 140px;
    padding: 10px;
    margin-bottom: 140px;
    display: flex;
}

@media (max-width: 480px){
    .container-load{
        width: 95%;
    }
}

.pass-rules{
    width: 100%;
    max-width: 392px;
    align-items: start;
}