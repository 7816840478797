.code-container {
    display: flex;
    flex-direction: row;
    margin-top: 3px;
    min-width: 75%;
    max-width: 392px;
    justify-content: space-between;
}

.resend-blck {
    display: flex;
    flex-direction: row;
    min-width: 75%;
    max-width: 392px;
    justify-content: flex-start;
    background-color: #525261;
    border-radius: 4px;
    height: 37px;
    color: #33FF93;
    font-size: 12px;
    margin-top: -10px;
    margin-bottom: 15px;
    align-items: center;
    font-family: AzoSans-Regular;
}

.code-box {
    box-sizing: border-box;
    height: 52px;
    width: 52px;
    border: 1px solid #FFFFFF;
    color: #FFFFFF;
    font-family: "Proxima Nova";
    font-size: 28px;
    font-weight: 800;
    letter-spacing: -0.2px;
    line-height: 32px;
    border-radius: 2px;
    background-color: #525261;
    text-align: center;
}

.code-box:focus {
    border: 2px solid #33FF93;
}

.error {
    border: 1px solid #EA4335;
}

.code-box:focus {
    outline: none;
}

@media (max-width: 480px) {
    .code-container, .resend-blck {
        width: 100%;
    }

    .code-box {
        height: 44px;
        width: 44px;
        font-size: 24px;
        font-weight: 600;
    }
}