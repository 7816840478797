.valid-mark{
    fill: green;
    transition: fill 600ms ease-in-out;
}

.invalid-mark{    
    fill: #000;
    stroke: #FFF;
    stroke-width: 1;
    transition: fill 600ms ease-in-out;
}

.svg-rect{
    fill: #000;
}