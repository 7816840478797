.valid-mark{
    fill: green;
    transition: fill 600ms ease-in-out;
}

.invalid-mark{
    fill: #f1f1f1;
    stroke: #000;
    stroke-width: 1;
    transition: fill 600ms ease-in-out;
}

.svg-rect{
    fill: #F1F1F1;
}